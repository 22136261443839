import React, { useEffect, useState } from "react";
import styles from './userhomework.module.css';
import { useParams } from "react-router-dom";
import axios from "../../../../axios/axios";
import Button from "../../../../Components/./Button/button";

function UserHomework() {
    const { userId, homeworkId, courseId } = useParams();
    const [user, setUser] = useState(null)
    const [homework, setHomework] = useState(null);
    // const [feedback, setFeedback] = useState('');
    const [points, setPoints] = useState('');
    // const [feedbackDisabled, setFeedbackDisabled] = useState(false)
    const [priceDisabled, setPriceDisabled] = useState(false)
    const notNegativeRegEx = /^\d*\.?\d*$/;

    useEffect(() => {
        axios.get(`/homework/getHomeWorkForTeacherForSingleUser/`,{
            params: {
                id: homeworkId,
                userId
            }
        } )
            .then(({ data }) => {
                setHomework(data.homeWorkInfo)
                setUser(data.user)
            });
    }, [homeworkId, userId]);

    // const handleFeedbackSubmit = () => {
    //     axios.patch('/homework/HomeworkFeedback', {
    //         feedback,
    //         userId,
    //         homeworkId
    //     }).then(() => setFeedbackDisabled(true));
    // };

    const handlePriceAssign = () => {
        axios.put(`/homework/addPoint?courseId=${courseId}`, {
            points,
            userId,
            homeworkId
        }).then(() => setPriceDisabled(true))
    }

    return (
        <div className={styles.container}>
            <div className={styles.user}>
                <div style={{ background: `url(${`https://platform.tesvan.com/server/${user?.image}`})` }} className={styles.student} />
                <p className={styles.name}>{user?.firstName} {user?.lastName}</p>
            </div>
            <div className={styles.descriptionCont + ' ql-snow'}>
                <div className={styles.pointsCont}>
                    <p className={styles.title}>Description</p>
                    {/*<span className={styles.points}>{homework?.maxPoints} points</span>*/}
                </div>
                <div className={styles.description + ' ql-editor'} dangerouslySetInnerHTML={{ __html: homework?.description}} />
                {(user?.answer || user?.files) ?
                    <>
                        <p className={styles.title}>Answer</p>
                        <div className={styles.homework + ' ql-editor'} dangerouslySetInnerHTML={{ __html: user?.answer }} />
                        <div className={styles.fileLinks}>
                            {user?.files?.map((file, index) => (
                                <div key={index}>
                                    <a href={file.fileLink} target="_blank" rel="noopener noreferrer" className={styles.link}>
                                        <div className={styles.filecont}>
                                            <div className={styles.checkcont}>
                                                <img src={'/images/checkGreen.png'} alt={'checkMark'} className={styles.check}/>
                                                {file.fileName}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </>
                    :
                    <p className={`${styles.description} ${styles.message}`}>The homework is not done yet.</p>
                }
            </div>
            {/*<div className={styles.feedbackcont}>*/}
            {/*    <p className={styles.feedback}>Leave your feedback!</p>*/}
            {/*    <textarea*/}
            {/*        disabled={user?.feedback}*/}
            {/*        className={styles.textarea}*/}
            {/*        value={!user?.feedback ? feedback : user?.feedback}*/}
            {/*        onChange={e => setFeedback(e.target.value)}*/}
            {/*        placeholder={'Here is your feedback'}*/}
            {/*    />*/}
            {/*    <Button*/}
            {/*        text={'Submit'}*/}
            {/*        className={"bigPrimary"}*/}
            {/*        handleButtonClick={handleFeedbackSubmit}*/}
            {/*        disabled={!feedback || feedbackDisabled}*/}
            {/*        styles={{ alignSelf: 'flex-end', padding: '13px 30px' }}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className={styles.feedbackcont}>
                <p className={styles.feedback}>Assessment</p>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <td></td>
                        <td>Max points</td>
                        <td>Mark</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Points from teacher</td>
                        <td>{homework?.point}</td>
                        <td>
                            <input
                                type={'number'}
                                className={styles.input}
                                value={user?.points ? user.points : points}
                                onChange={e => notNegativeRegEx.test(e.target.value) && setPoints(e.target.value)}
                                placeholder={'mark'}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                <Button
                    text={'Submit'}
                    className={"bigPrimary"}
                    handleButtonClick={handlePriceAssign}
                    disabled={!points || Number(points) > homework?.point || priceDisabled}
                    styles={{ alignSelf: 'flex-end', padding: '13px 30px' }}
                />
            </div>
        </div>
    );
}

export default UserHomework;
