import React, { useEffect, useState } from "react";
import styles from "./paymentTable.module.css";
import axios from "../../axios/axios";
import { useSearchParams } from "react-router-dom";
import { useUserCourseContext } from "../../Components/UserCourseContext/UserCourseContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PayingModal from "../../Pages/SingleCourse/PayingModal/PayingModal";
import Button from "../../Components/Button/button";

function PaymentTable() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [payment, setPayment] = useState(null);
    const [group, setGroup] = useState({});
    const [paymentData, setPaymentData] = useState({ paymentId: null, courseType: null });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { boughtCourses } = useUserCourseContext();
    const groupId = searchParams.get("groupId");
    const { t } = useTranslation();

    useEffect(() => {
        if (groupId) {
            axios.get('/payment/getUserPayment', { params: { groupId } })
                .then(({ data }) => setPayment(data))
                .catch(() => setPayment(null));
        }
    }, [groupId]);

    useEffect(() => {
        if (groupId) {
            axios.get(`/courses/getOne/${groupId}`, {params: {language: 'en'}})
                .then(({data}) => {
                    setGroup({
                        payment: data?.payment,
                        courseType: data.CoursesContents[0]?.courseType
                    });
                });
        }
    }, [groupId]);

    const handleChangeCourse = (id) => {
        searchParams.set('groupId', String(id));
        setSearchParams(searchParams);
    };

    const handlePayButtonClick = (id) => {
        setPaymentData({ paymentId: id, courseType: group?.courseType });
        setIsModalOpen(true);
    };

    const handleNextPaymentNavigation = (paymentMethod) => {
        axios.post(`payment/monthlyPaymentUrl?groupId=${groupId}`, { paymentWay: paymentMethod })
            .then(({ data }) => {
                if (paymentMethod === 'ARCA') {
                    window.open(data?.formUrl, '_blank');
                } else {
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = 'https://banking.idram.am/Payment/GetPayment';

                    const params = {
                        EDP_LANGUAGE: "EN",
                        EDP_REC_ACCOUNT: "100046705",
                        EDP_BILL_NO: data?.id,
                        EDP_AMOUNT: data?.amount,
                        EDP_DESCRIPTION: "Tesvan Platform Payment",
                    };

                    Object.keys(params).forEach(key => {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = key;
                        input.value = params[key];
                        form.appendChild(input);
                    });

                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                }
            })
            .catch(error => console.log(error));
    };

    const handleDownloadInvoice = (paymentId) => {
        axios.get(`payment/downloadInvoice`, {
            params: { paymentId },
            responseType: 'blob'
        }).then(({ data }) => {
            if (data.size === 0) {
                console.error("Received an empty file.");
                return;
            }

            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            const filename = `Payment_Invoice_${paymentId}.pdf`;

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log("Error downloading invoice:", error);
        });
    };



    return (
        <>
            {isModalOpen && (
                <PayingModal
                    setOpen={setIsModalOpen}
                    payment={group?.payment}
                    paymentData={paymentData}
                />
            )}
            <p className={styles.title}>{t('paymentDetails.title')}</p>
            <div className={styles.courseNamesContainer}>
                {boughtCourses?.map((group) => (
                    <span
                        key={group.id}
                        onClick={() => handleChangeCourse(group.id)}
                        className={styles.courseName}
                    >
                        {group.title}
                    </span>
                ))}
            </div>

            {payment?.responsData?.payments[0]?.type ? (
                <div className={styles.paymentDetails}>
                    <p className={styles.boldTitle}>{t('paymentDetails.details')}</p>
                    <div className={styles.monthlyPayment}>
                        <div className={styles.calendarContainer}>
                            <img
                                src="/images/calendarLink.png"
                                className={styles.calendar}
                                alt="calendar"
                            />
                            <div>
                                <p className={styles.boldTitle}>
                                    {payment?.responsData?.payments[0]?.type === 'monthly'
                                        ? t('paymentDetails.monthly')
                                        : t('paymentDetails.full')
                                    }&nbsp;
                                    {t('paymentDetails.payment')}
                                </p>
                                {payment?.responsData?.nextPaymentDate && (
                                    <div className={styles.courseNamesContainer}>
                                        <p className={`${styles.next} ${styles.red}`}>
                                            {t('paymentDetails.nextPayment')}
                                        </p>
                                        <p className={`${styles.next} ${styles.grey}`}>
                                            {t('paymentDetails.nextPaymentDate', {
                                                date: moment(payment?.responsData?.nextPaymentDate).format("DD MMM, YYYY"),
                                                amount: payment?.responsData?.userUnpaidSum
                                            })}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {payment?.responsData?.nextPaymentDate && (
                            <div className={styles.courseNamesContainer}>
                                <div
                                    className={styles.method}
                                    onClick={() => handleNextPaymentNavigation('ARCA')}
                                >
                                    <img
                                        src="/images/card.png"
                                        className={styles.methodImg}
                                        alt="card"
                                    />
                                    <p className={styles.methodName}>{t('payment.cards')}</p>
                                </div>
                                <div
                                    className={styles.method}
                                    onClick={() => handleNextPaymentNavigation('IDRAM')}
                                >
                                    <img
                                        src="/images/idramIcon.png"
                                        className={styles.methodImg}
                                        alt="idram"
                                    />
                                    <p className={styles.methodName}>{t('payment.Idram')}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <p className={styles.noPayment}>{t('paymentDetails.noPayment')}</p>
                    <div className={styles.buttonsContainer}>
                        {group?.payment?.map((method) => (
                            <Button
                                key={method.id}
                                text={t(`courseSinglePage.paymentMethods.paymentWays.${method.title.toLowerCase()}`)}
                                className="primary"
                                styles={{ width: "300px" }}
                                handleButtonClick={() => handlePayButtonClick(method?.id)}
                            />
                        ))}
                    </div>
                </>
            )}

            {payment?.responsData?.payments[0]?.type && (
                <div className={styles.paymentDetails}>
                    <div className={styles.row}>
                        <p className={styles.columnTitle}>{t('paymentDetails.method')}</p>
                        <p className={styles.columnTitle}>{t('paymentDetails.billingDate')}</p>
                        <p className={styles.columnTitle}>{t('paymentDetails.amount')}</p>
                        <p className={styles.columnTitle}>{t('paymentDetails.status')}</p>
                        <p className={styles.columnTitle}>Invoice</p>
                    </div>
                    <div className={styles.rowsContainer}>
                        {payment?.responsData?.payments.map(perPayment => (
                            <div className={styles.row} key={perPayment?.id}>
                                <p className={styles.boldTitle}>{perPayment.paymentWay}</p>
                                <p className={styles.boldTitle}>
                                    {moment(perPayment.createdAt).format("DD MMM, YYYY")}
                                </p>
                                <p className={styles.boldTitle}>
                                    {perPayment.amount} AMD
                                </p>
                                <div
                                    className={`${styles.status} ${
                                        perPayment.status === "Success"
                                            ? styles.paid
                                            : styles.failed
                                    }`}
                                >
                                    {perPayment.status === "Success"
                                        ? t('paymentDetails.paid')
                                        : t('paymentDetails.failed')}
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Button
                                        className={'primary'}
                                        text={'Download'}
                                        styles={{ margin: "auto" }}
                                        handleButtonClick={() => handleDownloadInvoice(perPayment?.id)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default PaymentTable;
