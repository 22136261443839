import React from "react";
import styles from  './teacher.module.css'
import User from "../../Dashboard/Home/User/User";
import TeacherOverview from "./TecherOverview/TeacherOverview";
import DashCalendar from "../../Dashboard/Home/Calendar/DashCalendar";
import CoursesInProgress from "./CoursesInProgress/CoursesInProgress";

function Teacher() {

    return(
        <section className={styles.section}>
            <div className={styles.container}>
                <TeacherOverview />
                <User />
            </div>
            <div className={styles.container}>
                <CoursesInProgress />
                <DashCalendar />
            </div>
        </section>
    )
}

export default Teacher;
