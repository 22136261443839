import styles from "../../../Admin/AdminDashboard/AdminMyGroup/AdminMyGroup.module.css"
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "../../../axios/axios";
import Loader from "../../../Components/Loader/Loader";
function PaymentGroups() {
    const [groups, setGroups] = useState({data: []});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('Group/findGroups')
            .then(response => {
                const data  = response.data.group;
                setGroups( data);
                setLoading(false);
            }).catch((e) => console.log(e))
    },[])

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div className={styles.inner}>
                        <p className={styles.tit}>Groups</p>
                    </div>
                    <div className={styles.container}>
                        {groups.map((i, index) => (
                            <Link to={`table/${i.id}`} key={index} className={styles.cont}>
                                <div className={styles.top}>
                                    <p className={styles.title}>
                                        {i.name}
                                    </p>
                                    <p className={styles.id}>{i.id}</p>
                                </div>
                                <div className={styles.botm}>
                                    <div className={styles.av}>
                                        <div className={styles.avatar}>
                                            <AvatarGroup >
                                                {i.GroupsPerUsers.slice(0, 3).map((l, index) => (
                                                    <Avatar key={index} alt={l.firstName} src={l.role === "STUDENT" ? `https://platform.tesvan.com/server/${l.image}` : null}/>
                                                ))}
                                            </AvatarGroup>
                                            {i.usersCount > 3 && <p className={styles.students}>+{i.usersCount - 3} users</p>}
                                            {i.usersCount <= 3 && <p className={styles.students}>{i.usersCount} {i.usersCount > 1 ? 'users' : 'user'}</p>}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default PaymentGroups;
