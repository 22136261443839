import React, { useState, useEffect } from "react";
import axios from "../../../axios/axios";
import styles from './studenttest.module.css';
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../Components/./Button/button";
import { useUserContext } from "../../../Components/UserContext/UserContext";
// import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Modal from "../../../Components/Modal/Modal";
import {Helmet} from "react-helmet-async";
// import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";

function StudentTest() {
    const { user } = useUserContext()
    // const {boughtCourses} = useUserCourseContext()
    const location = useLocation();
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    const testId = searchParams.get('testId');
    const language = searchParams.get('language')
    const [quizData, setQuizData] = useState(null);
    const [startQuiz, setStartQuiz] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [remainingTime, setRemainingTime] = useState(quizData?.time * 60);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [pointsData, setPointsData] = useState()
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        axios.get(`/testQuizz/findTest/${testId}`, {params: {testLanguage: language}})
            .then(response => {
                setQuizData(response?.data?.test);
                setSelectedAnswers(Array(response?.data?.test?.TestsQuizzs.length).fill(null));
            }).catch((e) => console.log(e))
    }, []);

    // useEffect(() => {
    //     if(quizData?.passDate) {
    //         navigate(`/${user.role.toLowerCase()}${(!boughtCourses?.length && user.role === ROLES.STUDENT) ? '/empty' : ''}/test`)
    //     }
    // }, [navigate])

    useEffect(() => {
        if (quizData) {
            setRemainingTime(quizData.time * 60);
        }
    }, [quizData]);

    useEffect(() => {
        if (quizData) {
            setSelectedAnswers(Array(quizData.TestsQuizzs.length).fill(null));
        }
    }, [quizData]);

    useEffect(() => {
        let timer;
        if (startQuiz && !isSubmitted && remainingTime > 0) {
            timer = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime === 0) {
                        clearInterval(timer);
                        axios.post(`/TestQuizz/finishCourse/${quizData.id}`).then(({data}) => {
                            setPointsData(data);
                            setOpenModal(true)
                        })
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [startQuiz, quizData, isSubmitted]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (startQuiz) {
                event.preventDefault();
                event.returnValue = '';
                const confirmationMessage = 'Are you sure you want to leave this quiz? Your progress will be lost.';
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [startQuiz]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleNextQuestion = () => {
        if (remainingTime > 0) {
            if (currentQuestionIndex < quizData?.TestsQuizzs.length - 1) {
                const questionId = quizData.TestsQuizzs[currentQuestionIndex].id;
                const optionId = quizData.TestsQuizzs[currentQuestionIndex].TestsQuizzOptions[selectedAnswer].id;

                axios.post('/TestQuizz/submit', {
                    testId: quizData.id,
                    questionId: questionId,
                    optionId: optionId
                }).then(() => {
                    setCurrentQuestionIndex(prevIndex => prevIndex + 1);
                    setSelectedAnswer(selectedAnswers[currentQuestionIndex + 1]);
                }).catch(() => console.log(quizData.id, questionId, optionId));
            } else {
                axios.post('/TestQuizz/submit', {
                    testId: quizData.id,
                    questionId: quizData.TestsQuizzs[currentQuestionIndex].id,
                    optionId: quizData.TestsQuizzs[currentQuestionIndex].TestsQuizzOptions[selectedAnswer].id
                }).then(() => {
                    axios.post(`/TestQuizz/finishCourse/${quizData.id}`).then(({data}) => {
                        setIsSubmitted(true);
                        setPointsData(data);
                    });
                });
            }
        } else {
            if (currentQuestionIndex < quizData?.TestsQuizzs.length - 1) {
                setCurrentQuestionIndex(prevIndex => prevIndex + 1);
                setSelectedAnswer(selectedAnswers[currentQuestionIndex + 1]);
            } else {
                setIsSubmitted(true)
            }
        }
    };

    const handleBackStep = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
            setSelectedAnswer(selectedAnswers[currentQuestionIndex - 1]);
        }
    };

    const handleSelectAnswer = (index) => {
        setSelectedAnswer(index);
        const updatedSelectedAnswers = [...selectedAnswers];
        updatedSelectedAnswers[currentQuestionIndex] = index;
        setSelectedAnswers(updatedSelectedAnswers);
    };

    const isNextDisabled = selectedAnswer === null;

    return (
        <>
            <Helmet>
                <title>{quizData?.title ? `${quizData?.title} | Tesvan Platform` : "| Tesvan Platform"}</title>
                <meta name="description" content="Participate in quizzes and tests to evaluate your knowledge and understanding of the subjects." />
                <meta name="viewport" content="width=1300, initial-scale=0.25" />
            </Helmet>
            {!isSubmitted ?
            <>
                <p className={styles.title}>{quizData?.title}</p>
                {!startQuiz ?
                    <div className={styles.container}>
                        <p className={styles.quizTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit dlor.</p>
                        <p className={styles.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        <Button className={"bigPrimary"} text={'START TEST'} styles={{ alignSelf: 'center', padding: '8px 30px' }} handleButtonClick={() => setStartQuiz(true)} />
                    </div>
                    :
                    <>
                        <div>
                            <div className={styles.numbersCont}>
                                <div className={styles.timerCont}>
                                    <div className={styles.textCont}>
                                        <p className={styles.title}>Let’s practice</p>
                                        <p className={styles.questionTitle}>{quizData?.description}</p>
                                    </div>
                                    <div className={styles.timer}>
                                        <img src={'/images/timer.png'} alt={'timer'} />
                                        <p className={styles.time}>{formatTime(remainingTime)}</p>
                                    </div>
                                </div>
                                <div className={styles.numbers}>
                                    {quizData?.TestsQuizzs.map((question, index) => (
                                        <div key={index} className={`${styles.circle} ${currentQuestionIndex === index ? styles.selectedCircle : ''}${currentQuestionIndex > index ? styles.beforeCircle : ''}`}>{index + 1}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.numbersCont} ${styles.quiz}`}>
                            <p className={styles.questionTitle}>{quizData?.TestsQuizzs[currentQuestionIndex]?.question}</p>
                            <div className={styles.quizAnswers}>
                                {quizData?.TestsQuizzs[currentQuestionIndex]?.TestsQuizzOptions.map((option, i) => (
                                    <div key={i} className={`${styles.answer} ${selectedAnswer === i ? styles.selected : ''}`} onClick={() => handleSelectAnswer(i)}>
                                        <p className={styles.number}>{i + 1}.</p>
                                        {option.option}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.buttons}>
                                <Button text={"Back"} className={'secondary'} styles={{ width: '110px', padding: '5px 10px', borderRadius: '6px' }} handleButtonClick={handleBackStep} />
                                <Button text={currentQuestionIndex < quizData?.TestsQuizzs.length - 1 ? "Next" : "Submit"} disabled={isNextDisabled} className={'primary'} styles={{ width: '110px', padding: '5px 10px', borderRadius: '6px' }} handleButtonClick={handleNextQuestion} />
                            </div>
                        </div>
                    </>
                }
            </>
            :
            <>
                <p className={styles.title}>{quizData?.title}</p>
                <div className={styles.container}>
                    <p className={styles.quizTitle}>Out of {quizData?.TestsQuizzs.length} questions, you only got {pointsData?.correctAnswers} correct answers</p>
                    <p className={styles.text}>{formatTime(quizData?.time * 60 - remainingTime)} mins</p>
                    <p className={styles.message}>
                        {pointsData?.point <= 40 ? 'You could pass better!' : pointsData?.point > 60 ? 'Great news!' : 'Keep up the good work!'}
                    </p>
                    <Button className={"bigPrimary"} text={'Go to dashboard'} styles={{ alignSelf: 'center', padding: '8px 30px' }} handleButtonClick={() => navigate(`/${user.role.toLowerCase()}`)} />
                </div>
                {/*<div className={styles.submitCont}>*/}
                    {/*<img src={ pointsData?.point > 60 ? '/images/successGirl.png' : '/images/rightGirl.png'} alt={'congratulationGirl'} className={pointsData?.point > 60 ? styles.image : styles.imageRight} />*/}
                    {/*<div className={styles.textCont}>*/}
                    {/*    <p className={styles.name}>*/}
                    {/*        Dear{' '}*/}
                    {/*        <TextHighlighter*/}
                    {/*            originalString={`${user.firstName} ${user.lastName}!`}*/}
                    {/*            startIndex={user.firstName.length}*/}
                    {/*            endIndex={100}*/}
                    {/*            white*/}
                    {/*        />*/}
                    {/*    </p>*/}
                    {/*    <p className={styles.messageText}>*/}
                    {/*</div>*/}
                    {/*        {pointsData?.point > 60 ?*/}
                    {/*            <>*/}
                    {/*                <span className={styles.yellow}>Great news!</span>*/}
                    {/*                You scored {pointsData?.correctAnswers} out of {quizData?.TestsQuizzs.length} on the test. You can now enroll in the course after payment.*/}
                    {/*            </>*/}
                    {/*            :*/}
                    {/*            <>*/}
                    {/*                <span className={styles.yellow}>{pointsData?.point <= 40 ? 'Keep up the good work!' : 'Good luck!'} </span>*/}
                    {/*                You answered {pointsData?.correctAnswers} out of {quizData?.TestsQuizzs.length} questions correctly.*/}
                    {/*                Despite the score, you can enroll in the course upon payment.*/}
                    {/*            </>*/}
                    {/*        }*/}
                    {/*</p>*/}
                    {/*    <Button*/}
                    {/*        className={'bigPrimary'}*/}
                    {/*        text={'Submit PaymentTable'}*/}
                    {/*        handleButtonClick={() => navigate(quizData.CourseId && '/payment/' + quizData.CourseId)}*/}
                    {/*        styles={{ alignSelf: 'flex-start'}}*/}
                    {/*    />*/}
                    {/*{ pointsData?.point <= 60 &&*/}
                    {/*    <p className={styles.info}>*/}
                    {/*        <img src={'/images/info.png'} alt={'info'} />*/}
                    {/*        Additionally, we'll also send skill improvement materials to your [{user?.email}] email.*/}
                    {/*    </p>*/}
                    {/*}*/}
                {/*</div>*/}
            </>
            }
            { openModal &&
                <Modal
                    setOpen={setOpenModal}
                    question={`Time's Up - Your Responses Won't Be Counted`}
                    description={'We regret to inform you that your time for this assessment has expired. Unfortunately, any answers submitted after the deadline will not be counted.'}
                    onConfirm={() => setOpenModal(false)}
                    confirmText={'Stay'}
                    closeText={'Exit'}
                    onClose={() => navigate(-1)}
                    boxShadow
                />
            }
        </>
    )
}
export default StudentTest;
