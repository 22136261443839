import React, { useEffect, useState } from "react";
import styles from "./adminpayment.module.css";
import axios from "../../../axios/axios";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import { CiSearch } from "react-icons/ci";

const tHeads = ['Name Surname', 'Method', 'Amount', 'Status', 'Type', 'Billing Date'];
function AdminPayment() {
    const [payment, setPayment] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { groupId } = useParams();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            axios.get('payment/getAllPayment', { params: { groupId, userName: searchTerm } })
                .then(({ data }) => setPayment(data));
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [groupId, searchTerm]);

    return (
        <>
            <div className={styles.titleContainer}>
                <p className={styles.title}>Payment</p>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder={"Search by username"}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.searchBar}
                    />
                    <CiSearch className={styles.searchIcon} />
                </div>
            </div>
            <table className={styles.table}>
                <thead>
                <tr>
                    {tHeads.map((name, i) => (
                        <th key={i}>
                            <span>{name}</span>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {payment?.payments?.map(payment => (
                    <tr key={payment?.id}>
                        <td>{payment?.user?.firstName} {payment?.user?.lastName}</td>
                        <td>{payment?.paymentWay}</td>
                        <td>{payment?.amount} AMD</td>
                        <td>
                                <span
                                    className={`${styles.status} ${
                                        payment?.status === "Success" ? styles.paid : styles.failed
                                    }`}
                                >
                                    {payment?.status}
                                </span>
                        </td>
                        <td>{payment?.type}</td>
                        <td>{moment(payment?.updatedAt).format("DD MMM, YYYY")}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
}

export default AdminPayment;
