import React, { useState } from "react";
import styles from './admincreatehomework.module.css'
import Button from "../../Components/Button/button";
import axios from "../../axios/axios";
import {FaMinus, FaPlus} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import RichEditor from "../../Components/RichEditor/RichEditor";
import CustomDate from "../../Components/Date/Date";
function AdminCreateHomework() {

    const [language, setLanguage] = useState('ENG')
    const [titleEn, setTitleEn] = useState('')
    const [titleAm, setTitleAm] = useState('')
    const [titleRu, setTitleRu] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [descriptionAm, setDescriptionAm] = useState('')
    const [descriptionRu, setDescriptionRu] = useState('')
    const [point, setPoint] = useState(0)
    const [dueDate, setDueDate] = useState(null)
    const navigate = useNavigate()

    const handleSave = () => {
        axios.post('/homework/create', {
            title_en: titleEn,
            title_am: titleAm,
            title_ru: titleRu,
            description_en: descriptionEn,
            description_am: descriptionAm,
            description_ru: descriptionRu,
            point,
            dueDate
        }).then(() => {
            navigate(-1)
        })
    }
    console.log(dueDate)

    return (
            <div className={styles.container}>
                <div className={styles.form}>
                    <p className={styles.title}>Add new homework</p>
                    <div className={styles.languages}>
                        <div className={`${styles.language} ${language === 'ENG' ? styles.selected : ''}`} onClick={() => setLanguage('ENG')}>English</div>
                        <div className={`${styles.language} ${language === 'ARM' ? styles.selected : ''}`} onClick={() => setLanguage('ARM')}>Armenian</div>
                        <div className={`${styles.language} ${language === 'RUS' ? styles.selected : ''}`} onClick={() => setLanguage('RUS')}>Russian</div>
                    </div>
                    {language === 'ENG' &&
                        <>
                            <p className={styles.label}>Name</p>
                            <div className={styles.inputContainer}>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder="Enter the name of the task"
                                    value={titleEn}
                                    onChange={(e) => setTitleEn(e.target.value)}
                                />
                            </div>
                        </>
                    }
                    {language === 'ARM' &&
                        <>
                            <label className={styles.label}>Name</label>
                            <div className={styles.inputContainer}>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder="Enter the name of the task"
                                    value={titleAm}
                                    onChange={(e) => setTitleAm(e.target.value)}
                                />
                            </div>
                        </>
                    }
                    {language === 'RUS' &&
                        <>
                            <label className={styles.label}>Name</label>
                            <div className={styles.inputContainer}>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder="Enter the name of the task"
                                    value={titleRu}
                                    onChange={(e) => setTitleRu(e.target.value)}
                                />
                            </div>
                        </>
                    }
                </div>
                <label className={styles.label}>Scores</label>
                <div>
                    <div className={styles.calendarContainer}>
                        <div className={styles.areaContainer}>
                            <div className={`${styles.input} ${styles.score}`} style={{ position: 'relative' }}>
                                <FaMinus className={styles.icons} onClick={() => setPoint(Math.max(0, point - 10))} />
                                <p>{point} pts</p>
                                <input
                                    type="number"
                                    value={point}
                                    className={styles.hiddenInput}
                                    onChange={(e) => setPoint(Number(e.target.value))}
                                />
                                <FaPlus className={styles.icons} onClick={() => setPoint(point + 10)} />
                            </div>
                        </div>
                        <CustomDate putDate={date => setDueDate(date)} selectedDate={dueDate} average />
                    </div>
                </div>
                {language === 'ENG' && (
                    <RichEditor value={descriptionEn} setValue={setDescriptionEn} />
                )}
                {language === 'ARM' && (
                    <RichEditor value={descriptionAm} setValue={setDescriptionAm} />
                )}
                {language === 'RUS' && (
                    <RichEditor value={descriptionRu} setValue={setDescriptionRu} />
                )}
                <Button
                    className="primary"
                    text="Save"
                    handleButtonClick={handleSave}
                    styles={{ alignSelf: 'center', padding: '8px 50px', marginTop: '25px'}}
                    disabled={!titleRu || !titleEn || !titleAm || !descriptionRu || !descriptionEn || !descriptionAm || !point}
                />
            </div>
    )
}

export default AdminCreateHomework;
