import styles from "../coursesprogress.module.css";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import React from "react";
import {Link} from "react-router-dom";
import {ROLES, useUserContext} from "../../../../Components/UserContext/UserContext";

function GroupProgress({index, title, users, usersCount, groupId}) {

    const { user } = useUserContext()

    return (
        <Link to={`/${user?.role.toLowerCase()}/myGroup/${groupId}${user?.role === ROLES.ADMIN ? '/members' : ''}`} className={styles.group}>
            <div className={styles.imageCont}>
                <img src={'/images/coursesInProgress.png'} className={styles.image} alt={'coursesInProgress'} />
                <span className={styles.number}>{index}{index === 2 ? "'nd" : "'st"} group</span>
            </div>
            <p className={styles.title}>{title}</p>
            <div className={styles.avatar}>
                <AvatarGroup>
                    {users.slice(0, 3).map((student, i) => (
                        <Avatar key={i} alt={student.student} src={student.role === "STUDENT" ? `https://platform.tesvan.com/server/${student.image}` : null}/>
                    ))}
                </AvatarGroup>
                <p className={styles.students}>{usersCount} {usersCount > 1 ? 'students' : 'student'}</p>
            </div>
        </Link>
    )
}

export default GroupProgress;

