import React, { useState, useEffect, useRef } from "react";
import axios from "../../../axios/axios";
import styles from '../../../Pages/Registration/RegistStep1/regfirststep.module.css'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import Date from "../../../Components/Date/Date";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import { RiArrowDownSLine } from "react-icons/ri";
import Country from "../../../Components/Country/Country";
import Button from "../../../Components/./Button/button";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";


const regex = /^[\p{L}\s\-']+$/u;
const regexEN = /^[A-Za-z\s\-']+$/;
const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

const languages = [
    {
        language: 'Eng',
        lang: 'en'
    },
    {
        language: 'Arm',
        lang: 'am'
    },
    {
        language: 'Rus',
        lang: 'ru'
    }
]

function Account() {
    const { i18n, t } = useTranslation()
    const { user } = useUserContext();
    const [initialUser] = useState(user);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [date, setDate] = useState(user.birthday);
    const [country, setCountry] = useState(user.country);
    const [phone, setPhone] = useState(user.phoneNumber);
    const [gender, setGender] = useState(user.gender);
    const [city, setCity] = useState(user.city);
    const [open, setOpen] = useState(false);
    const [phoneErr, setPhoneErr] = useState(null);
    const [nameErr, setNameErr] = useState(null);
    const [lastNameErr, setLastNameErr] = useState(null);
    const [emailErr, setEmailErr] = useState(null);
    const [cityErr, setCityErr] = useState(null);
    const [dateErr, setDateErr] = useState(null);
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [hover, setHover] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isEmailChanged, setIsEmailChanged] = useState(false)
    const [time, setTime] = useState(30);
    const [running, setRunning] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [start, setStart] = useState(false);
    const gen = [t('frequentlyUsedExpressions.male'), t('frequentlyUsedExpressions.female')];
    const [language, setLanguage] = useState( localStorage.getItem('language') || languages[1].lang)
    const inputFileRef = useRef(null);

    useEffect(() => {
        const hasChanges =
            firstName !== initialUser.firstName ||
            lastName !== initialUser.lastName ||
            email !== initialUser.email ||
            date !== initialUser.birthday ||
            country !== initialUser.country ||
            phone !== initialUser.phoneNumber ||
            gender !== initialUser.gender ||
            city !== initialUser.city;

        setIsButtonDisabled(
            !hasChanges ||
            nameErr ||
            lastNameErr ||
            emailErr ||
            dateErr ||
            phoneErr ||
            cityErr
        );
    }, [firstName, lastName, email, date, country, phone, gender, city, initialUser, nameErr, lastNameErr, emailErr, dateErr, phoneErr, cityErr]);

    useEffect(() => {
        if (running && time > 0) {
            const intervalId = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
        if (time === 0) {
            setRunning(false);
            setDisabled(true);
        }
        setTimeout(() => {
            setDisabled(true);
        }, 30000);
    }, [time, running]);

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return null;

        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedNumber) return false;

        const countryCodeLength = parsedNumber.countryCallingCode.length + 1;
        if (phoneNumber.length <= countryCodeLength) return null;

        return parsedNumber.isValid();
    };

    const handleFileChange = event => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            axios.patch('/user/changeUserImage', formData).then(({data}) => setSelectedAvatar(data?.url))
                .catch(error => console.log(error));
        }
    };

    const updateUser = e => {
        e.preventDefault();
        axios.put('/user/changeUserData', {
            id: user.id,
            firstName,
            lastName,
            phoneNumber: phone,
            birthday: date,
            gender,
            country,
            city
        }).then(() => {
            if (initialUser.email !== email) {
                axios.post('/user/ChangeEmail', {email})
                    .then(() => {
                        setIsEmailChanged(true);
                    })
            } else {
                setIsButtonDisabled(true)
            }
        })
    };

    const removeAvatarImage = e => {
        e.stopPropagation()
        axios.put('/user/deleteAvatar').then(() => setSelectedAvatar( !selectedAvatar ? 'defaultIcon.png' : null))
    }

    const changeLanguage = (lng) => {
        if (lng !== 'ru') {
            i18n.changeLanguage(lng).then(() => {
                setLanguage(lng);
                localStorage.setItem('language', lng)
                window.location.reload()
            });
        }
    };

    const resendLink = () => {
        axios.post('/user/ChangeEmail', {email})
            .then(() => {
                setTime(30);
                setStart(true);
                setRunning(true);
                setDisabled(false);
            })
    };

    return(
        <>
            <Helmet>
                <title>Account Settings - Update Your Information</title>
                <meta name="description" content="Update your account information, including personal details and contact information." />
                <meta name="viewport" content="width=1300, initial-scale=0.25" />
            </Helmet>
            {!isEmailChanged ?
                <>
                    {user?.role !== ROLES.ADMIN &&
                        <div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                ref={inputFileRef}
                            />
                            <div
                                className={styles.avatar}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                onClick={() => inputFileRef.current.click()}
                            >
                                {hover &&
                                    <div className={styles.hoverAvatar}>
                                        <p className={styles.changePhoto}>{t('settings.account.changePhoto')}</p>
                                        <hr className={styles.line} />
                                        <p
                                            className={styles.remove}
                                            onClick={removeAvatarImage}
                                        >
                                            {t('settings.account.remove')}
                                        </p>
                                    </div>
                                }
                                <div style={{background: `url(${selectedAvatar ? `https://platform.tesvan.com/server/${selectedAvatar}` : user?.image ? `https://platform.tesvan.com/server/${user?.image}` : `https://platform.tesvan.com/server/defaultIcon.png`})`}} className={styles.user} />
                            </div>
                        </div>
                    }
                    <div className={`${styles.container} ${styles.containerSettings} ${user?.role === ROLES.ADMIN ? styles.marginTop : ''}`}>
                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('registration.firstStep.firstName')} </span>
                                <input
                                    type="text"
                                    className={`${styles.input} ${nameErr === null || !nameErr ? '' : styles.errorinp}`}
                                    value={firstName}
                                    placeholder={t('registration.firstStep.firstNamePlaceholder')}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    onBlur={() => {
                                        setNameErr(!regexEN.test(firstName));
                                    }}
                                />
                            </label>
                            {nameErr && <p className={styles.error}>{t('registration.firstStep.firstNameError')}</p>}
                        </div>
                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}>{t('registration.firstStep.lastName')}</span>
                                <input
                                    type="text"
                                    className={`${styles.input} ${lastNameErr === null || !lastNameErr ? '' : styles.errorinp}`}
                                    value={lastName}
                                    placeholder={t('registration.firstStep.lastNamePlaceholder')}
                                    onChange={(e) => setLastName(e.target.value)}
                                    onBlur={() => {
                                        setLastNameErr(!regexEN.test(lastName));
                                    }}
                                />
                            </label>
                            {lastNameErr && <p className={styles.error}>{t('registration.firstStep.lastNameError')}</p>}
                        </div>
                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('registration.firstStep.email')} </span>
                                <input
                                    type="text"
                                    className={`${styles.input} ${emailErr === null || !emailErr ? '' : styles.errorinp}`}
                                    value={email}
                                    placeholder="johnsmith@example.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={() => {
                                        setEmailErr(!emailRegex.test(email));
                                    }}
                                />
                            </label>
                            {emailErr && <p className={styles.error}>{t('registration.firstStep.emailError')}</p>}
                        </div>
                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('registration.firstStep.birthDate')} </span>
                                <div style={{width: '48%'}}>
                                    <Date putDate={(newDate) => setDate(newDate)} selectedDate={date} error={setDateErr} />
                                    {dateErr && <p className={styles.error}>{t('registration.firstStep.birthDateError')}</p>}
                                </div>
                            </label>
                        </div>
                        <div className={styles.inputSettings} style={{ position: 'relative' }}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('registration.firstStep.country')} </span>
                                <div>
                                    <input
                                        className={styles.input}
                                        style={{ cursor: 'pointer' }}
                                        placeholder={t('registration.firstStep.countryPlaceholder')}
                                        type="text"
                                        value={country}
                                        readOnly={true}
                                        onClick={() => setOpen(!open)}
                                    />
                                    <RiArrowDownSLine
                                        className={!open ? styles.arrow : styles.arrow  + ' ' + styles.arrowhov}
                                    />
                                </div>
                                {open &&
                                    <Country
                                        value={(selectedCountry) => setCountry(selectedCountry)}
                                        setOpen={setOpen}
                                    />
                                }
                            </label>
                        </div>
                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('registration.firstStep.phone')}</span>
                                <PhoneInput
                                    defaultCountry="am"
                                    value={phone}
                                    name="phoneInput"
                                    className={`${styles.phone} ${phoneErr !== null && phoneErr ? styles.phoneerr : ''}`}
                                    onChange={(phone) => setPhone(phone)}
                                    onBlur={() => {
                                        setPhoneErr(phone ? !validatePhoneNumber(phone) : null);
                                    }}
                                />
                            </label>
                            {phoneErr && <p className={styles.error}>{t('registration.firstStep.phoneError')}</p>}
                        </div>
                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('registration.firstStep.gender')} </span>
                                <div className={`${styles.input} ${styles.radio}`}>
                                    {gen.map((i, index) => {
                                        return (
                                            <div key={index}>
                                                <RadioButton
                                                    text={i}
                                                    value={i}
                                                    name={i}
                                                    checked={gender === (i === gen[0] ? 'Male' : 'Female')}
                                                    onChange={() => setGender(i === gen[0] ? 'Male' : 'Female')}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </label>
                        </div>

                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('registration.firstStep.city')}  </span>
                                <input
                                    type="text"
                                    className={`${styles.input} ${cityErr === null || !cityErr ? '' : styles.errorinp}`}
                                    value={city}
                                    placeholder={t('registration.firstStep.cityPlaceholder')}
                                    onChange={(e) => setCity(e.target.value)}
                                    onBlur={() => {
                                        setCityErr(!regex.test(city));
                                    }}
                                />
                            </label>
                            {cityErr && <p className={styles.error}>{t('registration.firstStep.cityError')}</p>}
                        </div>
                        <div className={styles.inputSettings}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('settings.account.language')} </span>
                                <div className={styles.input + ' ' + styles.radio}>
                                    {languages.map((i, index) => {
                                        return (
                                            <div key={index}>
                                                <RadioButton
                                                    text={i.language}
                                                    value={i.lang}
                                                    name={i.language}
                                                    checked={language === i.lang}
                                                    onChange={() => changeLanguage(i.lang)}
                                                    disabled={i.lang === 'ru'}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </label>
                        </div>
                    </div>
                    <Button className={'bigPrimary'} text={t('settings.update')} styles={{ alignSelf: 'flex-end', margin: ' 20px 40px 0 0' }} handleButtonClick={updateUser} disabled={isButtonDisabled} />
                </>
                :
                <div className={styles.emailVerification}>
                    <p className={styles.headingVerification}>
                        <TextHighlighter
                            originalString={'Verify your email address'}
                            startIndex={13}
                            endIndex={25}
                            white
                        />
                    </p>
                    <p className={styles.textVerification}>
                        We have sent an email to <span className={styles.mailVerification}>[{email}]</span> to verify your email address
                        and activate your account. The link in the email will expire in 24 hours.
                    </p>
                    <p className={styles.textVerification}>
                        Click <Link to={'/changeEmail'} className={styles.link}>here</Link> if you would like to change the email address you signed up with.
                    </p>
                    <p className={styles.spamVerification}>
                        Not in inbox or spam folder?
                    </p>
                    <div className={styles.buttonVerification}>
                        <Button
                            className="primary"
                            text="Resend link"
                            disabled={!disabled}
                            handleButtonClick={resendLink}
                        />
                        {start &&
                            <p className={styles.timerVerification}>
                                {time.toString().length === 1 ? `00:0${time}` : `00:${time}`}
                            </p>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Account;
