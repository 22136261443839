import React, { useRef, useState } from "react";
import styles from "./regfirststep.module.css";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CustomDate from "../../../Components/Date/Date";
import Country from "../../../Components/Country/Country";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import { RiArrowDownSLine } from "react-icons/ri";
import { useFormContext } from "../../../Components/FormContext/FormContext";
import Button from "../../../Components/./Button/button";
import { Link } from "react-router-dom";
import axios from "../../../axios/axios";
import { useTranslation } from "react-i18next";

function RegFirstStep({ putStep }) {
    const { formData, updateFormData } = useFormContext();
    const [open, setOpen] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState(formData.email);
    const [phoneErr, setPhoneErr] = useState(null);
    const [nameErr, setNameErr] = useState(null);
    const [lastNameErr, setLastNameErr] = useState(null);
    const [emailErr, setEmailErr] = useState(null);
    const [cityErr, setCityErr] = useState(null);
    const [dateErr, setDateErr] = useState(null);
    const [error, setError] = useState(null);
    const countryRef = useRef(null);
    const { t } = useTranslation();
    const gen = [t('frequentlyUsedExpressions.male'), t('frequentlyUsedExpressions.female')];

    const regex = /^[\p{L}\s\-']+$/u;
    const regexEN = /^[A-Za-z\s\-']+$/;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    const handleInputChange = (field, value) => {
        updateFormData({ [field]: value });
    };

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return null;

        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedNumber) return false;

        const countryCodeLength = parsedNumber.countryCallingCode.length + 1;
        if (phoneNumber.length <= countryCodeLength) return false;

        return parsedNumber.isValid();
    };

    const handleDateChange = (date) => {
        updateFormData({
            ...formData,
            birthday: date,
        });
    };

    const handleNext = (e) => {
        e.preventDefault();

        const isPhoneValid = validatePhoneNumber(formData.phoneNumber);
        setPhoneErr(!isPhoneValid);
        if (!isPhoneValid) {
            return;
        }

        setSubmittedEmail(formData.email);
        axios.get(`/register/emailExist/${formData.email}`, {})
            .then(() => {
                window.scrollTo(0, 80);
                putStep();
            })
            .catch((error) => {
                setError(error?.response?.data?.message);
                setEmailErr(true);
            });
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.firstName')} </span>
                        <input
                            type="text"
                            className={`${styles.input} ${nameErr !== null && nameErr ? styles.errorinp : ''}`}
                            value={formData.firstName}
                            placeholder={t('registration.firstStep.firstNamePlaceholder')}
                            name="firstName"
                            onChange={(e) => handleInputChange("firstName", e.target.value)}
                            onBlur={() => {
                                setNameErr(formData.firstName ? !regexEN.test(formData.firstName) : null);
                            }}
                        />
                    </label>
                    {nameErr && <p className={styles.error}>{t('registration.firstStep.firstNameError')}</p>}
                </div>
                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.lastName')} </span>
                        <input
                            type="text"
                            className={`${styles.input} ${lastNameErr !== null && lastNameErr ? styles.errorinp : ''}`}
                            value={formData.lastName}
                            placeholder={t('registration.firstStep.lastNamePlaceholder')}
                            name="lastName"
                            onChange={(e) => handleInputChange("lastName", e.target.value)}
                            onBlur={() => {
                                setLastNameErr(formData.lastName ? !regexEN.test(formData.lastName) : null);
                            }}
                        />
                    </label>
                    {lastNameErr && <p className={styles.error}>{t('registration.firstStep.lastNameError')}</p>}
                </div>
                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.email')} </span>
                        <input
                            type="text"
                            className={`${styles.input} ${emailErr !== null && emailErr ? styles.errorinp : ''}`}
                            value={formData.email}
                            placeholder="johnsmith@example.com"
                            name="email"
                            onChange={(e) => handleInputChange("email", e.target.value)}
                            onBlur={() => {
                                if (formData.email && formData.email !== submittedEmail) {
                                    setEmailErr(!emailRegex.test(formData.email));
                                } else {
                                    setEmailErr(null);
                                }
                            }}
                        />
                    </label>
                    {emailErr && <p className={styles.error}>{error ? t('registration.firstStep.existingEmailError') : t('registration.firstStep.emailError')}</p>}
                </div>
                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.phone')} </span>
                        <PhoneInput
                            defaultCountry="am"
                            value={formData.phoneNumber}
                            name="phoneInput"
                            className={`${styles.phone} ${phoneErr !== null && phoneErr ? styles.phoneerr : ''}`}
                            onChange={(phone) => handleInputChange("phoneNumber", phone)}
                            onBlur={() => {
                                setPhoneErr(formData.phoneNumber ? !validatePhoneNumber(formData.phoneNumber) : null);
                            }}
                        />
                    </label>
                    {phoneErr && <p className={styles.error}>{t('registration.firstStep.phoneError')}</p>}
                </div>
                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.birthDate')} </span>
                        <div className={styles.inputcont}>
                            <CustomDate putDate={handleDateChange} selectedDate={formData.birthday} error={setDateErr} />
                            {dateErr && <p className={styles.error}>{t('registration.firstStep.birthDateError')}</p>}
                        </div>
                    </label>
                </div>
                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.gender')} </span>
                        <div className={`${styles.input} ${styles.radio}`}>
                            {gen.map((i, index) => {
                                return (
                                    <div key={index}>
                                        <RadioButton
                                            text={i}
                                            value={i}
                                            name={i}
                                            checked={formData.gender === (i === gen[0] ? 'Male' : 'Female')}
                                            onChange={() => updateFormData({ gender: i === gen[0] ? 'Male' : 'Female' })}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </label>
                </div>
                <div className={styles.inputcont} style={{ position: 'relative' }}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.country')} </span>
                        <div ref={countryRef}>
                            <input
                                className={`${styles.input}`}
                                style={{ cursor: 'pointer' }}
                                placeholder={t('registration.firstStep.countryPlaceholder')}
                                type="text"
                                value={formData.country}
                                readOnly={true}
                                onClick={() => setOpen(!open)}
                                name="country"
                            />
                            <RiArrowDownSLine
                                id={'countryArrow'}
                                className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {open && (
                            <Country
                                value={(selectedCountry) => updateFormData({ country: selectedCountry })}
                                setOpen={setOpen}
                                inputRef={countryRef}
                            />
                        )}
                    </label>
                </div>
                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}> {t('registration.firstStep.city')} </span>
                        <input
                            type="text"
                            className={`${styles.input} ${cityErr !== null && cityErr ? styles.errorinp : ''}`}
                            value={formData.city}
                            placeholder={t('registration.firstStep.cityPlaceholder')}
                            name="city"
                            onChange={(e) => handleInputChange("city", e.target.value)}
                            onBlur={() => {
                                setCityErr(formData.city ? !regex.test(formData.city) : null);
                            }}
                        />
                    </label>
                    {cityErr && <p className={styles.error}>{t('registration.firstStep.cityError')}</p>}
                </div>
            </div>
            <div className={styles.button}>
                <Button
                    disabled={
                        (formData.phoneNumber === "" ||
                            formData.firstName === "" ||
                            formData.lastName === "" ||
                            formData.email === "" ||
                            formData.city === "" ||
                            formData.gender === "" ||
                            formData.country === "" ||
                            formData.birthday === "") ||
                        (nameErr || lastNameErr || emailErr || cityErr || dateErr || phoneErr) ||
                        (error && formData.email === submittedEmail)
                    }
                    handleButtonClick={handleNext}
                    text={t('registration.next')}
                    className={"primary"}
                />
            </div>
            <p className={styles.text} style={{ textAlign: 'right', paddingTop: '20px' }}>
                {t('registration.haveAccount')} {' '}
                <Link
                    to={'/login'}
                    className={styles.link}
                >
                    {t('registration.logIn')}
                </Link>
            </p>
        </>
    );
}

export default RegFirstStep;
